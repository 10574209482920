<template>
  <div class="exercise-book-detail">
    <div class="go-back">
      <span @click="prev" class="prev">{{i18n.Home.exerciseBook}}</span><span>{{i18n.Home.JobList}}</span>
    </div>
    <div class="exercise-book-detail-content">
      <div class="exercise-book-detail-header" v-if="resultData.job_detail">
        <div class="title">
          <h3>{{ resultData.job_detail.title }}</h3>
          <div
            class="show-btn"
            @click="getAllasses"
            v-if="resultData.all_asses.status == 1"
          >
            {{ $t('m.seeEvaluate') }}
          </div>
        </div>
        <div class="time">
          <span>{{i18n.Home.Release}}:{{ resultData.job_detail.start_time }}</span>
          <span>{{i18n.Home.deadline}}:{{ resultData.job_detail.end_time }}</span>
        </div>
      </div>
      <ul>
        <li v-for="(item, index) of list" :key="item.id">
          <div class="exercise-book-detail-item" @click="jump(item)">
            <div class="exercise-book-detail-text">
              <span> {{ item.absIndex }}. </span>
              <div class="name">{{ item.title }}</div>
              <div class="tag">#{{ item.gre_id }}</div>
              <div class="tag type">{{ item.cate_name }}</div>
            </div>
            <div v-if="item.is_finsh" class="finsh-btn">
              <img :src="require(`@/assets/success-icon.png`)" />
            </div>
            <div class="look-btn" v-else>
              {{ $t('m.ToDoTheTopic') }}
            </div>
          </div>
          <div class="evaluate" v-if="item.tag && item.tag !== undefined">
            <div class="tag-intro">
              <i class="f-icon icon-Price tag-icon" />{{i18n.Home.JobEvaluation}}：{{ item.tag }}
            </div>
            <div
              class="evaluate-content"
              v-if="(item.audio && item.audio.length) || item.content"
            >
              <img :src="item.teacher_info.img_url" v-if="item.teacher_info" />
              <div>
                <p class="teacher-name" v-if="item.teacher_info">
                  {{ item.teacher_info.name }} <span>{{i18n.Home.teacher}}</span>
                </p>
                <div
                  v-for="(audioItem, audioIndex) in item.audio"
                  :key="audioIndex"
                  class="audio-list"
                >
                  <Audio :url="audioItem" />
                </div>
                <div class="evaluate-text" v-if="item.content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-pagination
      small
      layout="prev, pager, next"
      class="exercise-book-detail-pagination"
      @current-change="fetchList"
      :page-count="pagination.pageCount"
      :page-size="pagination.pageSize"
      :current-page.sync="pagination.currentPage"
    >
    </el-pagination>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="786"
      :before-close="handleClose"
      class="exercise-book-detail-dialog"
      v-if="resultData.all_asses && dialogVisible"
    >
      <div class="title-dialog">{{i18n.Home.Overall}}</div>
      <div class="evaluate">
        <div class="tag-intro">
          <i class="f-icon icon-Price tag-icon" />{{i18n.Home.JobEvaluation}}：{{
            resultData.all_asses.title
          }}
        </div>
        <div
          class="evaluate-content"
          v-if="
            (resultData.all_asses.audio && resultData.all_asses.audio.length) ||
              resultData.all_asses.content
          "
        >
          <img
            :src="resultData.all_asses.teacher_info.img_url"
            v-if="resultData.all_asses.teacher_info"
          />
          <div>
            <p class="teacher-name" v-if="resultData.all_asses.teacher_info">
              {{ resultData.all_asses.teacher_info.name }} <span>{{i18n.Home.teacher}}</span>
            </p>
            <div
              v-for="(assesItem, assesIndex) in resultData.all_asses.audio"
              :key="assesIndex"
              class="audio-list"
            >
              <Audio :url="assesItem" />
            </div>
            <div class="evaluate-text" v-if="resultData.all_asses.content">
              {{ resultData.all_asses.content }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiJobDetail } from '@/api/api.js'
import Audio from '@/components/Audio/Audio.vue'

export default {
  components: {
    Audio
  },
  data() {
    return {
      list: [],
      teachOptions: [],
      classOptions: [],
      teachid: '',
      classId: '',
      pagination: {
        pageCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      jobId: '',
      resultData: {},
      dialogVisible: false
    }
  },
  created() {
    this.jobId = this.$route.query.id
    this.fetchList()
  },
   computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    prev() {
      this.$router.push({ name: 'ExerciseBook' })
    },
    fetchList() {
      apiJobDetail({
        page: this.pagination.currentPage,
        page_size: this.pagination.pageSize,
        job_id: this.jobId
      }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.resultData = res.data
        this.list = (res.data.question_list || []).map((item, index) => ({
          ...item,
          absIndex:
            (this.pagination.currentPage - 1) * this.pagination.pageSize +
            index +
            1
        }))
        this.pagination.pageCount = res.data.pager.page_cnt
        if (this.resultData.all_asses.status == 1) {
          let str = ''
          for (var i = 0; i < this.resultData.all_asses.title.length; i++) {
            if (i == this.resultData.all_asses.title.length - 1) {
              str += this.resultData.all_asses.title[i]
            } else {
              str += this.resultData.all_asses.title[i] + ','
            }
          }
          this.resultData.all_asses.title = str
        }
      })
    },
    jump(data) {
      if (data.classify) {
        this.$router.push({
          name: 'Exercises',
          query: { clid: data.clid, practiceId: data.id, claid: this.jobId }
        })
      } else {
        sessionStorage.setItem('practice_id', data.id)
        sessionStorage.setItem('caqid', data.caqid)
        this.$router.push({ name: 'SkillExerciseTask' })
      }
    },
    getAllasses() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.exercise-book-detail-dialog {
  .el-dialog__body {
    margin-top: -30px;
  }
  .title-dialog {
    padding-bottom: 8px;
    color: #a4afb7;
    font-size: 14px;
    border-bottom: 1px solid #ebebf2;
  }
}
.exercise-book-detail {
  .go-back {
    color: #3b86ff;
    padding: 24px 0 20px;
    font-weight: 400;
    font-size: 14px;
    .prev {
      display: inline-block;
      cursor: pointer;
      &::after {
        content: '>';
        margin: 0 10px;
      }
    }
  }
  .time {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #a4afb7;
    span {
      margin-right: 24px;
    }
  }
  .tag {
    padding: 0 13px;
    height: 20px;
    line-height: 20px;
    background: #ebebf2;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    color: #a4afb7;
    margin-right: 10px;
    &.type {
      background: #c1e6fe;
      color: #345d7f;
    }
  }
  .evaluate {
    padding: 20px 10px 0;
    color: #7c8084;
    font-size: 14px;
    .tag-intro {
      line-height: 22px;
      letter-spacing: 1px;
    }
    .tag-icon {
      color: #3b86ff;
      margin-right: 8px;
    }
    .evaluate-content {
      margin-top: 19px;
      padding: 21px 24px;
      background: #ebebf2;
      border-radius: 10px;
      display: flex;
      img {
        flex-shrink: 0;
        width: 42px;
        height: 42px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .teacher-name {
        margin-bottom: 2px;
        span {
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
    .audio-content {
      width: 272px;
      background: #fff;
    }
    .evaluate-text {
      width: 415px;
      padding: 14px 16px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      margin-top: 6px;
      color: #1f1f40;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
  .exercise-book-detail-content {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    ul {
      padding: 0 40px;
      li {
        padding: 24px 0;
        color: #1f1f40;
        font-size: 14px;
        border-bottom: 1px solid #ebebf2;
        &:last-child {
          border: none;
        }
        .name {
          margin: 0 17px 0 5px;
        }
        .exercise-book-detail-text {
          display: flex;
        }
        .exercise-book-detail-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        }
      }
    }
  }
  .finsh-btn {
    margin-right: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .look-btn {
    width: 63px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: #3b86ff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 12px;
  }
  .exercise-book-detail-header {
    padding: 0 40px;
    height: 111px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    .title {
      display: flex;
      padding: 34px 0 8px;
    }
    .show-btn {
      width: 109px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      background: #3b86ff;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      margin-left: auto;
    }
  }
  .exercise-book-detail-pagination {
    text-align: center;
    margin-top: 15px;
    &.el-pagination--small {
      .el-pager li,
      .btn-prev,
      .btn-next {
        border: 1px solid rgba(199, 202, 222, 1);
        border-radius: 3px;
        margin: 0 3px;
        color: #c7cade;
      }
      .el-pager li.active {
        border: 1px solid rgba(59, 134, 255, 1);
        color: #3b86ff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 4px;
      }
      .el-pager li.btn-quicknext,
      .el-pager li.btn-quickprev {
        border: none;
      }
    }
  }
}
</style>
